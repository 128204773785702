
.platform-page {
    .platform-wrap {
        min-height: 200px;
        ul.abroad {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;
            li {
                //width: 196px;
                margin-right: 13px;
                margin-bottom: 40px;
                width: 229px;
                a {
                    display: block;
                    position: relative;
                    z-index: 0;
                    cursor: pointer;
                    img {
                        display: block;
                        width: 229px;
                        height: 140px;
                    }
                    h6 {
                        line-height: 50px;
                        background: #f5f7f9;
                        font-size: 20px;
                        font-weight: bold;
                        color: #2b221b;
                        text-align: center;
                        width: 229px;
                        padding: 0 10px;
                        box-sizing: border-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    p {
                        position: absolute;
                        width: 229px;
                        height: 190px;
                        left: 0;
                        top: 0;
                        background: rgba(0, 0, 0, 0.7);
                        z-index: 2;
                        padding: 24px 20px 0 20px;
                        cursor: pointer;
                        display: none;
                        span {
                            display: block;
                            font-size: 16px;
                            color: #fff;
                            line-height: 28px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 5; // 控制多行的行数
                            line-clamp: 5;
                            -webkit-box-orient: vertical;
                            box-sizing: border-box;
                        }
                    }
                }
                a:hover {
                    p {
                        display: block;
                    }
                }
            }
            li:nth-child(5n + 5) {
                margin-right: 0;
            }
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        margin-top: 30px;
    }
}
